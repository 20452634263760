.adminImageryReviewContainerDiv {
    flex: 1;
    flex-direction: column;
    display: flex;
    position: relative;
  }
  
.adminImageryReviewBody {
    flex: 1;
}

.adminImageryReviewLeftAlignedActions {
  position: absolute;
  left: 10px;
  top: 10px;
  display: flex;
  flex-direction: column;
  z-index: 200;
}

.adminImageryReviewSelectAnalyticCard {
  display: flex;
  flex-direction: column;
  min-height: 45px;
  min-width: 240px;
  padding: 0px;
  overflow: hidden;
}


.adminImageryReviewSelectAnalyticCardSquareInfo {
  padding: 10px;
  padding-right: 15px;
  display: flex;
  flex-direction: column;
}

.adminImageryReviewSelectAnalyticCardSquareInfo h5 {
  margin: 0px;
  font-size: 14px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;

}

.adminImageryReviewSelectAnalyticCardSquareInfo h5 img {
  width: 24px;
  height: 24px;
  margin-right: 0px;
  border-radius: 50%;
}

.adminImageryReviewSelectAnalyticCardSquareInfo h5 span {
  font-weight: 400;
  padding-left: 10px;
  max-width: 175px;
}

.adminImageryReviewSelectAnalyticCardSquareInfo h5 span div {
  display: flex;
  flex-direction: column;

}

.adminImageryReviewSelectAnalyticCardSquareInfo h5 span div div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.adminImageryReviewContainerSliderDiv {
  height: 0px;
  transition-duration: 0.5s;
  background-color: #ffffff;
  padding-right: 60px;
  padding-left: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.adminImageryReviewDateSlider {
  position: relative;
}